import { ArrowBackIosNew as ArrowBackIosNewIcon } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  ButtonBase,
  Typography,
  Skeleton,
} from '@mui/material';
import { useEffect, useState, useContext } from 'react';
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { getProductSubCategories } from '../api/product';
import { CartContext } from '../contexts/CartContext';
import { AuthContext } from '../contexts/AuthContext';
import { formatNumberToCurrency } from '../utils/currency';

const SubcategoryCard = ({ subcategory }) => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  return (
    <Grid item xs={6}>
      <ButtonBase
        component={RouterLink}
        state={{ subcategory }}
        to={subcategory.id.toString()}
        sx={{ borderRadius: 3.75, height: 1, width: 1 }}
      >
        <Paper
          elevation={3}
          sx={{
            borderRadius: 3.75,
            overflow: 'hidden',
            height: 1,
            width: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {isImageLoading && (
            <Skeleton
              variant="rectangle"
              sx={{ height: '9.375rem' }}
              animation="wave"
            />
          )}
          <Box
            component="img"
            sx={[
              {
                width: 1,
                objectFit: 'cover',
                display: 'block',
                aspectRatio: '1',
              },
              isImageLoading && {
                display: 'none',
              },
            ]}
            src={subcategory.imageUrl}
            alt=""
            onLoad={() => setIsImageLoading(false)}
          />
          <Container
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: 0,
            }}
          >
            <Typography
              variant="caption"
              component="div"
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                m: 1.25,
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '2',
                overflow: 'hidden',
              }}
            >
              {subcategory.name}
            </Typography>
          </Container>
        </Paper>
      </ButtonBase>
    </Grid>
  );
};

const MenuSubcategory = () => {
  const navigate = useNavigate();
  const { categoryId } = useParams();

  const { state } = useLocation();
  const category = state && state.category;
  const [subcategories, setSubcategories] = useState([]);
  const { cartProducts, getCartProductsTotalPrice, getCartProductsTotalItems } =
    useContext(CartContext);
  const { admin } = useContext(AuthContext);

  useEffect(() => {
    const controller = new AbortController();

    const bootstrapAsync = async () => {
      try {
        const { data } = await getProductSubCategories(
          { id: categoryId },
          {
            signal: controller.signal,
          }
        );

        if (data.length > 0)
          setSubcategories(data.filter((item) => item.status));
        else setSubcategories(null);
      } catch (error) {
        console.error(error);
      }
    };

    bootstrapAsync();

    return () => controller.abort();
  }, [admin, categoryId]);

  return (
    <>
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <IconButton
            edge="start"
            size="large"
            color="inherit"
            sx={{ ml: -0.25 }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: 'bold', flexGrow: 1, mx: 0.25 }}
          >
            {category ? category.name : <Skeleton animation="wave" />}
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Grid
        container
        spacing={2.25}
        sx={[
          { px: 2.5, pt: 2.25 },
          cartProducts.length > 0 ? { pb: 11.625 } : { pb: 2.25 },
        ]}
      >
        {subcategories &&
          subcategories.length > 0 &&
          subcategories.map((subcategory) => (
            <SubcategoryCard subcategory={subcategory} key={subcategory.id} />
          ))}
        {subcategories &&
          subcategories.length === 0 &&
          [...new Array(10)].map((_, index) => (
            <Grid item xs={6} key={index}>
              <Skeleton
                variant="rectangle"
                sx={{
                  height: '12.5260625rem',
                  borderRadius: 3.75,
                }}
                animation="wave"
              />
            </Grid>
          ))}
        {!subcategories && (
          <Grid item xs>
            <Typography>No data</Typography>
          </Grid>
        )}
      </Grid>
      {cartProducts.length > 0 && (
        <Container
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            px: 2.5,
            bgcolor: 'background.default',
          }}
          maxWidth="false"
        >
          <Button
            variant="contained"
            sx={{
              width: 1,
              borderRadius: 2.5,
              height: '3.125rem',
              display: 'flex',
              justifyContent: 'space-between',
              px: 3,
              my: 1.5625,
            }}
            disableElevation
            component={RouterLink}
            to="/checkout"
          >
            <Typography>{getCartProductsTotalItems()} items</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              {formatNumberToCurrency(getCartProductsTotalPrice())}
            </Typography>
          </Button>
        </Container>
      )}
    </>
  );
};

export default MenuSubcategory;
