import {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import { CartContext } from './CartContext';
import {
  getAuthDetail,
  logIn as requestLogIn,
  logOut as requestLogOut,
} from '../api/auth';

export const AuthContext = createContext({
  accessToken: null,
  admin: null,
  logIn: async () => {},
  logOut: async () => {},
  checkAccessToken: async () => {},
});

export const AuthContextProvider = ({ children }) => {
  const [admin, setAdmin] = useState();
  const [accessToken, setAccessToken] = useState(
    localStorage.getItem('accessToken')
  );
  const { clearCart } = useContext(CartContext);

  useEffect(() => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
    } else {
      localStorage.removeItem('accessToken');
    }
  }, [accessToken]);

  const logIn = async ({ emailAddress, password }) => {
    const { data } = await requestLogIn({ emailAddress, password });
    if (!data.administrator.permissions.includes('manage-pos')) {
      throw new Error(''); // TODO: Implement appropriate error message
    }
    setAdmin(data.administrator);
    setAccessToken(data.accessToken);
  };

  const logOut = async () => {
    try {
      if (window.navigator.onLine) {
        await requestLogOut();
      }
    } finally {
      localStorage.removeItem('cartProducts');
      clearCart();

      setAccessToken(null);
      setAdmin(null);
    }
  };

  const checkAccessToken = useCallback(
    async (config) => {
      try {
        const { data } = await getAuthDetail(config);
        if (!data.permissions.includes('manage-pos')) {
          throw new Error(''); // TODO: Implement appropriate error message
        }
        setAdmin(data);
      } catch (error) {
        setAccessToken(null);
        localStorage.removeItem('cartProducts');
        clearCart();

        throw error;
      }
    },
    [clearCart]
  );

  return (
    <AuthContext.Provider
      value={{
        accessToken,
        admin,
        logIn,
        logOut,
        checkAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
