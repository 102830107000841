import { ArrowBackIosNew as ArrowBackIosNewIcon } from '@mui/icons-material';
import {
  AppBar,
  ButtonBase,
  Chip,
  Grid,
  IconButton,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import groupBy from 'array.prototype.groupby';
import { Fragment, useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getHistories } from '../api/history';
import { formatNumberToCurrency } from '../utils/currency';

const Histories = () => {
  const navigate = useNavigate();
  const [historyGroups, setHistoryGroups] = useState([]);

  useEffect(() => {
    const controller = new AbortController();

    const bootstrapAsync = async () => {
      try {
        const {
          data: { histories },
        } = await getHistories({ signal: controller.signal });

        const tempHistories = histories.filter(
          (history) => history.thirdPartyDetail.id
        );

        setHistoryGroups(
          Object.entries(
            groupBy(tempHistories, ({ createdAt }) =>
              new Date(createdAt).toLocaleDateString('id', {
                dateStyle: 'full',
              })
            )
          ).reduce((previousValue, [date, histories]) => {
            previousValue.push({ date, histories });
            return previousValue;
          }, [])
        );
      } catch (error) {
        console.error(error);
      }
    };

    bootstrapAsync();

    return () => controller.abort();
  }, []);

  return (
    <>
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <IconButton
            edge="start"
            size="large"
            color="inherit"
            sx={{ ml: -0.25 }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: 'bold', flexGrow: 1 }}
          >
            Histories
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Stack sx={{ px: 2.5, my: 2.5 }} spacing={2.5}>
        {historyGroups.map((historyGroup, index) => (
          <Fragment key={index}>
            <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
              {historyGroup.date}
            </Typography>
            {historyGroup.histories.map((history) => (
              <ButtonBase
                key={history.id}
                sx={{ borderRadius: 2.5, textAlign: 'initial' }}
                component={RouterLink}
                to={history.id}
                elevation={2}
              >
                <Paper sx={{ p: 1.5, borderRadius: 2.5, width: 1 }}>
                  <Grid container rowSpacing={1.25}>
                    <Grid
                      container
                      item
                      sx={{ alignItems: 'center' }}
                      columnSpacing={1.25}
                    >
                      <Grid item>
                        <Typography sx={{ fontSize: '0.8125rem' }}>
                          {history.thirdPartyDetail.referenceCode}
                        </Typography>
                      </Grid>
                      <Grid item xs>
                        <Chip
                          label={
                            history.status === 200
                              ? 'Paid'
                              : history.status === 201
                              ? 'Finished'
                              : null
                          }
                          color="primary"
                          sx={[
                            {
                              height: '1.25rem',
                              fontSize: '0.625rem',
                              borderRadius: 1.25,
                            },
                            history.status === 200 && {
                              bgcolor: 'warning.main',
                            },
                            history.status === 201 && {
                              bgcolor: 'success.main',
                            },
                          ]}
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="caption"
                          sx={{ color: 'grey.500' }}
                        >
                          {new Date(history.createdAt).toLocaleTimeString(
                            'en-US',
                            {
                              hour12: false,
                              timeStyle: 'short',
                            }
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container item columnSpacing={1.25}>
                      <Grid item xs>
                        <Typography variant="caption" color="primary">
                          {history.thirdPartyDetail.paymentMethod.name}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="caption"
                          sx={{ fontWeight: 'medium' }}
                        >
                          {formatNumberToCurrency(history.total)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Paper>
              </ButtonBase>
            ))}
          </Fragment>
        ))}
      </Stack>
    </>
  );
};

export default Histories;
