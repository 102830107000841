import CssBaseline from '@mui/material/CssBaseline';
import {
  createTheme,
  experimental_sx as sx,
  responsiveFontSizes,
  ThemeProvider,
} from '@mui/material/styles';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from './App';

const theme = responsiveFontSizes(
  createTheme({
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: sx({
            bgcolor: 'background.default',
            color: 'common.black',
          }),
        },
      },
      MuiButton: {
        styleOverrides: {
          root: sx({
            borderRadius: 1.25,
            textTransform: 'none',
          }),
        },
      },
    },
    palette: {
      primary: {
        main: '#2196f3',
        dark: '#1769aa',
        light: '#4dabf5',
        contrastText: '#fff',
      },
    },
  })
);

ReactDOM.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </StrictMode>,
  document.getElementById('root')
);
