import { posAxios } from '../utils/api';

export const getProductsByCategory = ({ id }, config) =>
  posAxios.get(`product-categories/${id}/products`, config);

export const getProductCategories = (config) =>
  posAxios.get('product-categories', config);

export const getProductCategory = ({ id }, config) =>
  posAxios.get(`product-categories/${id}`, config);

export const getProductSubCategories = ({ id }, config) =>
  posAxios.get(`product-categories/${id}/subcategories`, config);

export const getProductsBySubcategory = ({ id }, config) =>
  posAxios.get(`product-subcategories/${id}/products`, config);

export const getProductByName = (params) =>
  posAxios.get('product-categories/product/search', { params: params });
