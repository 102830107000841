import { alpha, useTheme } from '@mui/material/styles';
import { ArrowBackIosNew as ArrowBackIosNewIcon } from '@mui/icons-material';
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  Box,
  Button,
  Container,
  FormHelperText,
} from '@mui/material';
import { useState } from 'react';

const DriverDetail = ({ driver, onSubmitClick, onBackClick }) => {
  const theme = useTheme();
  const [name, setName] = useState(driver ? driver.name : '');
  const [phone, setPhone] = useState(driver ? driver.phone : '');
  const [isNameError, setIsNameError] = useState(false);
  const [isPhoneError, setIsPhoneError] = useState(false);

  return (
    <>
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <IconButton
            edge="start"
            size="large"
            color="inherit"
            sx={{ ml: -0.25 }}
            onClick={onBackClick}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: 'bold', flexGrow: 1, mx: 0.25 }}
          >
            Driver Detail
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Box
        component="form"
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          !name && setIsNameError(true);
          !phone && setIsPhoneError(true);
          if (name && phone) onSubmitClick({ name, phone });
        }}
      >
        <Box sx={{ mx: 2.5 }}>
          <FormControl
            variant="standard"
            required
            error={isNameError}
            fullWidth
            sx={{ mt: 2.5 }}
          >
            <InputLabel
              htmlFor="name-input"
              shrink
              sx={{ color: 'common.black', transform: 'none' }}
            >
              Name
            </InputLabel>
            <OutlinedInput
              id="name-input"
              variant="outlined"
              sx={{
                mt: 3.5,
                height: '2.875rem',
              }}
              inputProps={{
                sx: {
                  typography: 'body2',
                  py: 1.75,
                },
              }}
              inputMode="text"
              value={name}
              onChange={(event) => {
                setIsNameError(false);
                setName(event.target.value);
              }}
            />
            {isNameError && (
              <FormHelperText>Please enter a name</FormHelperText>
            )}
          </FormControl>

          <FormControl
            variant="standard"
            required
            error={isPhoneError}
            fullWidth
            sx={{ mt: 3.5, flex: 1 }}
          >
            <InputLabel
              htmlFor="phone-number-input"
              shrink
              sx={{ color: 'common.black', transform: 'none' }}
            >
              Phone Number
            </InputLabel>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  mt: 3.5,
                  width: '2.875rem',
                  height: '2.875rem',
                  borderRadius: 2,
                  bgcolor: alpha(theme.palette.primary.dark, 0.1),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography sx={{ color: 'primary.dark' }} component="span">
                  +62
                </Typography>
              </Box>
              <OutlinedInput
                id="phone-number-input"
                type="tel"
                variant="outlined"
                placeholder="8123456xxxx"
                sx={{
                  mt: 3.5,
                  flex: 1,
                  ml: 1.875,
                  height: '2.875rem',
                }}
                inputProps={{
                  pattern: '[1-9]\\d*',
                  sx: {
                    typography: 'body2',
                    py: 1.75,
                  },
                }}
                value={phone}
                onChange={(event) => {
                  if (event.target.validity.valid || !event.target.value) {
                    setIsPhoneError(false);
                    setPhone(event.target.value);
                  }
                }}
              />
            </Box>
            {isPhoneError && (
              <FormHelperText>Please enter a phone number</FormHelperText>
            )}
          </FormControl>
        </Box>
        <Container
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            px: 2.5,
            bgcolor: 'background.default',
          }}
          maxWidth="false"
        >
          <Button
            variant="contained"
            sx={{
              typography: 'h6',
              width: 1,
              borderRadius: 2.5,
              height: '3.125rem',
              my: 1.875,
            }}
            disableElevation
            type="submit"
          >
            Submit
          </Button>
        </Container>
      </Box>
    </>
  );
};

export default DriverDetail;
