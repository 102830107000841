import {
  History as HistoryIcon,
  Image,
  Logout as LogoutIcon,
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  Toolbar,
  ButtonBase,
  Typography,
  Skeleton,
  InputAdornment,
  TextField,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { useEffect, useState, useContext, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { getProductByName, getProductCategories } from '../api/product';
import { CartContext } from '../contexts/CartContext';
import { AuthContext } from '../contexts/AuthContext';
import { formatNumberToCurrency } from '../utils/currency';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash.debounce';

const CategoryCard = ({ category }) => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  return (
    <Grid item xs={6}>
      <ButtonBase
        component={RouterLink}
        state={{ category }}
        to={category.id.toString()}
        sx={{ borderRadius: 3.75, height: 1, width: 1 }}
      >
        <Paper
          elevation={3}
          sx={{
            borderRadius: 3.75,
            overflow: 'hidden',
            height: 1,
            width: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {isImageLoading && (
            <Skeleton
              variant="rectangle"
              sx={{ height: '9.375rem' }}
              animation="wave"
            />
          )}
          <Box
            component="img"
            sx={[
              {
                width: 1,
                objectFit: 'cover',
                display: 'block',
                aspectRatio: '1',
              },
              isImageLoading && {
                display: 'none',
              },
            ]}
            src={category.imageUrl}
            alt=""
            onLoad={() => setIsImageLoading(false)}
          />
          <Container
            sx={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: 0,
            }}
          >
            <Typography
              variant="caption"
              component="div"
              sx={{
                textAlign: 'center',
                fontWeight: 'bold',
                m: 1.25,
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '2',
                overflow: 'hidden',
              }}
            >
              {category.name}
            </Typography>
          </Container>
        </Paper>
      </ButtonBase>
    </Grid>
  );
};

const MenuCategory = () => {
  const [categories, setCategories] = useState([]);

  const {
    cartProducts,
    addCartProduct,
    getCartProductsTotalPrice,
    getCartProductsTotalItems,
  } = useContext(CartContext);
  const { logOut, admin } = useContext(AuthContext);

  useEffect(() => {
    const controller = new AbortController();

    const bootstrapAsync = async () => {
      try {
        const { data } = await getProductCategories({
          signal: controller.signal,
        });

        setCategories(data.filter((item) => item.status));
      } catch (error) {
        console.error(error);
      }
    };

    bootstrapAsync();

    return () => controller.abort();
  }, [admin]);

  const handleLogOutClick = logOut;

  // ! if feld search empty

  const bootstrapAsync = async () => {
    try {
      const { data } = await getProductCategories({});
      setDataProduct([]);
      setCategories(data.filter((item) => item.status));
    } catch (error) {
      console.error(error);
    }
  };

  // ! search product
  const [dataProduct, setDataProduct] = useState([]);
  const [search, setSearch] = useState('');
  const [productSearch, setProductSearch] = useState('');

  const debounceOnChange = useRef(
    debounce((value) => {
      setProductSearch(value);
    }, 1000)
  ).current;

  const searchProduct = async () => {
    let params = {
      keywords: productSearch,
    };
    try {
      const data = await getProductByName(params);
      setDataProduct(data.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    productSearch === '' ? bootstrapAsync() : searchProduct();
  }, [productSearch]);

  return (
    <>
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: 'bold', flexGrow: 1 }}
          >
            {admin.outlet.name}
          </Typography>
          <IconButton
            color="inherit"
            size="large"
            component={RouterLink}
            to="/histories"
          >
            <HistoryIcon />
          </IconButton>
          <IconButton color="inherit" size="large" onClick={handleLogOutClick}>
            <LogoutIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
      {/* product search */}
      <Grid container spacing={2.25} sx={[{ px: 2.5, pt: 2.25 }]}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            placeholder={'Search'}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              debounceOnChange(e.target.value);
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '10px',
                paddingLeft: '6px',
                fontSize: '14px',
                fontWeight: 600,
              },
              bgcolor: '#FAFAFA',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ width: '24px' }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      {dataProduct.length !== 0 ? (
        <>
          <Grid container spacing={2.25} sx={[{ px: 2.5, pt: 2.25 }]}>
            {dataProduct.map((item, idx) => (
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: 2,
                    background: '#FFFFFF',
                    boxShadow: '1px 1px 3px #9E9D9D',
                    padding: '12px',
                    borderRadius: '10px',
                  }}
                >
                  <Box
                    component="img"
                    src={item?.imageUrl}
                    sx={{
                      height: '85px',
                      width: '85px',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                  />

                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexFlow: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                      {item?.name}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
                        {item?.price}
                      </Typography>
                      <Button
                        variant="contained"
                        onClick={() => addCartProduct(item)}
                      >
                        <Typography sx={{ fontSize: '12px', fontWeight: 500 }}>
                          Tambah
                        </Typography>
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
          {cartProducts.length > 0 && (
            <Container
              sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                px: 2.5,
                bgcolor: 'background.default',
              }}
              maxWidth="false"
            >
              <Button
                variant="contained"
                sx={{
                  width: 1,
                  borderRadius: 2.5,
                  height: '3.125rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  px: 3,
                  my: 1.5625,
                }}
                disableElevation
                component={RouterLink}
                to="/checkout"
              >
                <Typography>{getCartProductsTotalItems()} items</Typography>
                <Typography sx={{ fontWeight: 'bold' }}>
                  {formatNumberToCurrency(getCartProductsTotalPrice())}
                </Typography>
              </Button>
            </Container>
          )}
        </>
      ) : (
        <>
          <Grid
            container
            spacing={2.25}
            sx={[
              { px: 2.5, pt: 2.25 },
              cartProducts.length > 0 ? { pb: 11.625 } : { pb: 2.25 },
            ]}
          >
            {categories.length > 0
              ? categories.map((category) => (
                  <CategoryCard category={category} key={category.id} />
                ))
              : [...new Array(10)].map((_, index) => (
                  <Grid item xs={6} key={index}>
                    <Skeleton
                      variant="rectangle"
                      sx={{
                        height: '12.5260625rem',
                        borderRadius: 3.75,
                      }}
                      animation="wave"
                    />
                  </Grid>
                ))}
          </Grid>
          {cartProducts.length > 0 && (
            <Container
              sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                px: 2.5,
                bgcolor: 'background.default',
              }}
              maxWidth="false"
            >
              <Button
                variant="contained"
                sx={{
                  width: 1,
                  borderRadius: 2.5,
                  height: '3.125rem',
                  display: 'flex',
                  justifyContent: 'space-between',
                  px: 3,
                  my: 1.5625,
                }}
                disableElevation
                component={RouterLink}
                to="/checkout"
              >
                <Typography>{getCartProductsTotalItems()} items</Typography>
                <Typography sx={{ fontWeight: 'bold' }}>
                  {formatNumberToCurrency(getCartProductsTotalPrice())}
                </Typography>
              </Button>
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default MenuCategory;
