import { alpha, useTheme } from '@mui/material/styles';
import { ArrowBackIosNew as ArrowBackIosNewIcon } from '@mui/icons-material';
import {
  AppBar,
  IconButton,
  Toolbar,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  Box,
  Button,
  Container,
  FormHelperText,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { kadaAxios } from '../utils/api';
import { fetchOTP, submitOTP } from '../api/auth';

const CustomerDetail = ({
  customer,
  onSubmitClick,
  onBackClick,
  isExist,
  setIsExist,
  setAccesTokenCustomer,
  typeOrder,
}) => {
  const theme = useTheme();
  const [name, setName] = useState(customer ? customer.name : '');
  const [phone, setPhone] = useState(customer ? customer.phone : '');
  const [isNameError, setIsNameError] = useState(false);
  const [isPhoneError, setIsPhoneError] = useState(false);

  const [hasRequestedOTP, setHasRequestedOTP] = useState(false);
  const [otp, setOTP] = useState('');
  const [isOTPError, setIsOTPError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [isMatchOTPError, setIsMatchOTPError] = useState(false);

  const [otpCounter, setOtpCounter] = useState(30);
  const [isSentOTP, setIsSentOTP] = useState(false);

  useEffect(() => {
    if (isSentOTP) {
      if (otpCounter === 0) {
        setOtpCounter(30);
        setIsSentOTP(false);
      } else {
        setTimeout(() => {
          setOtpCounter(otpCounter - 1);
        }, 1000);
      }
    }
  }, [isSentOTP, otpCounter]);

  const getOTP = async ({ phone }) => {
    const formData = new FormData();

    formData.append('country_code', '62');
    formData.append('phone', phone);
    const dataInput = {
      countryCode: '62',
      phone: phone,
    };

    try {
      setIsLoading(true);
      const {
        data: { namaLengkap },
      } = await fetchOTP(dataInput);

      if (namaLengkap) {
        setIsExist(true);
        setName(namaLengkap);
      } else {
        setIsExist(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setHasRequestedOTP(true);
      setIsLoading(false);
      setIsSentOTP(true);
    }
  };

  const verifyOTP = async ({ phone, name }) => {
    if (otp.length === 6) {
      const formData = new FormData();

      formData.append('country_code', '62');
      formData.append('phone', phone);
      formData.append('otp', otp);
      formData.append('name', name);
      const inputData = {
        countryCode: '62',
        phone: phone,
        otp: otp,
        name: name,
      };

      try {
        setIsLoading(true);
        const data = await submitOTP(inputData);

        setAccesTokenCustomer(data.data?.accessToken);

        onSubmitClick({ name, phone });
      } catch (error) {
        console.error(error);

        if (error.response.status === 401) {
          setIsMatchOTPError(true);
          setIsOTPError(true);
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsOTPError(true);
    }
  };

  return (
    <>
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <IconButton
            edge="start"
            size="large"
            color="inherit"
            sx={{ ml: -0.25 }}
            onClick={onBackClick}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: 'bold', flexGrow: 1, mx: 0.25 }}
          >
            {typeOrder === 2 ? ' Driver Detail' : ' Customer Detail'}
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Box
        component="form"
        noValidate
        onSubmit={(event) => {
          event.preventDefault();

          !phone && setIsPhoneError(true);
          if (phone) {
            if (hasRequestedOTP) {
              !name && setIsNameError(true);
              name && verifyOTP({ phone, name });
            } else {
              getOTP({ phone });
            }
          }
        }}
      >
        <Box sx={{ mx: 2.5 }}>
          <FormControl
            variant="standard"
            required
            error={isPhoneError}
            fullWidth
            sx={{ mt: 3.5, flex: 1 }}
          >
            <InputLabel
              htmlFor="phone-number-input"
              shrink
              sx={{ color: 'common.black', transform: 'none' }}
            >
              Phone Number
            </InputLabel>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box
                sx={{
                  mt: 3.5,
                  width: '2.875rem',
                  height: '2.875rem',
                  borderRadius: 2,
                  bgcolor: alpha(theme.palette.primary.dark, 0.1),
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Typography sx={{ color: 'primary.dark' }} component="span">
                  +62
                </Typography>
              </Box>
              <OutlinedInput
                id="phone-number-input"
                type="tel"
                variant="outlined"
                placeholder="8123456xxxx"
                sx={{
                  mt: 3.5,
                  flex: 1,
                  ml: 1.875,
                  height: '2.875rem',
                }}
                inputProps={{
                  pattern: '[1-9]\\d*',
                  sx: {
                    typography: 'body2',
                    py: 1.75,
                  },
                }}
                value={phone}
                onChange={(event) => {
                  if (event.target.validity.valid || !event.target.value) {
                    setIsPhoneError(false);
                    setPhone(event.target.value);
                  }
                }}
              />
            </Box>
            {isPhoneError && (
              <FormHelperText>Please enter a phone number</FormHelperText>
            )}
          </FormControl>

          {hasRequestedOTP && (
            <FormControl
              variant="standard"
              required
              error={isNameError}
              fullWidth
              sx={{ mt: 2.5 }}
            >
              <InputLabel
                htmlFor="name-input"
                shrink
                sx={{ color: 'common.black', transform: 'none' }}
              >
                Name
              </InputLabel>
              <OutlinedInput
                id="name-input"
                variant="outlined"
                sx={{
                  mt: 3.5,
                  height: '2.875rem',
                }}
                inputProps={{
                  sx: {
                    typography: 'body2',
                    py: 1.75,
                  },
                }}
                inputMode="text"
                value={name}
                readOnly={isExist && Boolean(name)}
                onChange={(event) => {
                  setIsNameError(false);
                  setName(event.target.value);
                }}
              />
              {isNameError && (
                <FormHelperText>Please enter a name</FormHelperText>
              )}
            </FormControl>
          )}

          {hasRequestedOTP && (
            <FormControl
              variant="standard"
              required
              error={isOTPError}
              fullWidth
              sx={{ mt: 3.5, flex: 1 }}
            >
              <InputLabel
                htmlFor="otp-input"
                shrink
                sx={{ color: 'common.black', transform: 'none' }}
              >
                OTP
              </InputLabel>
              <OutlinedInput
                id="phone-number-input"
                type="tel"
                variant="outlined"
                placeholder="123456"
                sx={{
                  mt: 3.5,
                  flex: 1,
                  height: '2.875rem',
                }}
                inputProps={{
                  pattern: '[0-9]\\d*',
                  sx: {
                    typography: 'body2',
                    py: 1.75,
                  },
                  maxLength: 6,
                }}
                value={otp}
                onChange={(event) => {
                  if (event.target.validity.valid || !event.target.value) {
                    setIsOTPError(false);
                    setOTP(event.target.value);
                  }
                }}
              />

              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {isOTPError && (
                  <FormHelperText>
                    {otp.length < 6 && otp.length > 0
                      ? 'OTP must be 6 digits'
                      : isMatchOTPError
                      ? 'Invalid OTP'
                      : 'Please enter the OTP'}
                  </FormHelperText>
                )}
                <Box
                  sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      mt: '3px',
                      color: !isSentOTP ? 'primary.main' : 'rgba(0,0,0,0.5)',
                      cursor: 'pointer',
                    }}
                    onClick={() => !isSentOTP && getOTP({ phone })}
                  >
                    Send Again {isSentOTP && `(${otpCounter}s)`}
                  </Typography>
                </Box>
              </Box>
            </FormControl>
          )}
        </Box>
        <Container
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            px: 2.5,
            bgcolor: 'background.default',
          }}
          maxWidth="false"
        >
          <Button
            variant="contained"
            sx={{
              typography: 'h6',
              width: 1,
              borderRadius: 2.5,
              height: '3.125rem',
              my: 1.875,
            }}
            disableElevation
            type="submit"
            disabled={isLoading}
          >
            {hasRequestedOTP ? 'Verify' : 'Get'} OTP
          </Button>
        </Container>
      </Box>
    </>
  );
};

export default CustomerDetail;
