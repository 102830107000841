import { posAxios } from '../utils/api';

export const getHistories = (config) =>
  // TODO: Fix hardcoded values
  posAxios.get('histories', {
    ...config,
    params: { page: 1, pageSize: 1000000 },
  });

export const getHistory = ({ id }, config) =>
  posAxios.get(`histories/${id}`, config);
