import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@mui/icons-material';
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  OutlinedInput,
  Typography,
  FormHelperText,
} from '@mui/material';
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AuthContext } from '../contexts/AuthContext';

const Login = () => {
  const { logIn, checkAccessToken, admin, accessToken } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailAddressError, setIsEmailAddressError] = useState(false);
  const [isPasswordError, setIsPasswordError] = useState(false);

  useEffect(() => {
    const controller = new AbortController();

    const bootstrapAsync = async () => {
      try {
        await checkAccessToken({ signal: controller.signal });
        navigate('/categories', { replace: true });
      } catch (error) {
        console.error(error);
      }
    };

    if (accessToken && !admin) {
      bootstrapAsync();

      return () => controller.abort();
    }
  }, [checkAccessToken, navigate, admin, accessToken]);

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!emailAddress) setIsEmailAddressError(true);
    if (!password) setIsPasswordError(true);

    if (!emailAddress || !password) return;

    try {
      await logIn({ emailAddress, password });
      navigate('/categories', { replace: true });
    } catch (error) {
      console.error(error);
    }
  };
  const handleEmailAddressChange = (event) =>
    setEmailAddress(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);

  return (
    <Stack
      component="form"
      noValidate
      sx={{ height: '100vh', pt: 2.5 }}
      onSubmit={handleFormSubmit}
    >
      <Typography
        variant="h4"
        sx={{ textAlign: 'center', fontWeight: 600, mx: 2.5 }}
        color="primary"
      >
        Enak POS
      </Typography>
      <FormControl
        variant="standard"
        sx={{ mt: 5, mx: 2.5 }}
        error={isEmailAddressError}
      >
        <InputLabel
          htmlFor="email-input"
          shrink
          sx={{ color: 'common.black', transform: 'none' }}
        >
          Email
        </InputLabel>
        <OutlinedInput
          id="email-input"
          variant="outlined"
          type="email"
          sx={{
            bgcolor: 'grey.200',
            mt: 3.5,
            borderRadius: 1.25,
          }}
          inputProps={{
            sx: {
              typography: 'body2',
            },
          }}
          value={emailAddress}
          onChange={handleEmailAddressChange}
        />
        {isEmailAddressError && (
          <FormHelperText>Please enter your email</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant="standard"
        sx={{ mt: 2.5, mx: 2.5 }}
        error={isPasswordError}
      >
        <InputLabel
          htmlFor="password-input"
          shrink
          sx={{ color: 'common.black', transform: 'none' }}
        >
          Password
        </InputLabel>
        <OutlinedInput
          id="password-input"
          type={isPasswordShown ? 'text' : 'password'}
          sx={{
            bgcolor: 'grey.200',
            mt: 3.5,
            borderRadius: 1.25,
          }}
          inputProps={{
            sx: {
              typography: 'body2',
            },
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={() => setIsPasswordShown((value) => !value)}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
              >
                {isPasswordShown ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          }
          value={password}
          onChange={handlePasswordChange}
        />
        {isPasswordError && (
          <FormHelperText>Please enter your password</FormHelperText>
        )}
      </FormControl>
      <Button
        variant="contained"
        disableElevation
        sx={{ py: 1.75, fontSize: '1rem', mt: 5, mx: 2.5, borderRadius: 2 }}
        type="submit"
      >
        Log in
      </Button>
    </Stack>
  );
};

export default Login;
