import {
  ArrowBackIosNew as ArrowBackIosNewIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  createInvoice,
  getPaymentMethods,
  getOutletTables,
  summarizeCart,
  getOutletOrderTypes,
} from '../api/transaction';
import { AuthContext } from '../contexts/AuthContext';
import { CartContext } from '../contexts/CartContext';
import { SnackbarContext } from '../contexts/SnackbarContext';
import { formatNumberToCurrency } from '../utils/currency';
import CustomerDetail from './CustomerDetail';
import DriverDetail from './DriverDetail';
import { updatePinCustomer, verifyPinCustomer } from '../api/kantong';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

function useDebounce(value, delay, initialValue = '') {
  const [debouncedValue, setDebouncedValue] = useState(initialValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay, initialValue]);

  return debouncedValue;
}

const Checkout = () => {
  const { admin } = useContext(AuthContext);
  const {
    cartProducts,
    updateCartProductQty,
    removeCartProduct,
    updateCartProductNote,
    clearCart,
    getCartProductsTotalItems,
  } = useContext(CartContext);
  const { open: openSnackbar } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const currentCartProductsTotalItems = useRef();
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState();
  const [referenceCode, setReferenceCode] = useState('');
  const [codeVoucher, setCodeVoucher] = useState('');
  const [customerDiscount, setCustomerDiscount] = useState('');
  const [externalGrandtotal, setExternalGrantotal] = useState('');
  const debouncedCustomerDiscount = useDebounce(customerDiscount, 500, '');
  const debouncedExternalGrandtotal = useDebounce(externalGrandtotal, 500, '');
  const [priceSummary, setPriceSummary] = useState();
  const [isSummarizing, setIsSummarizing] = useState(true);
  const [isDriverDialogOpen, setIsDriverDialogOpen] = useState(false);
  const [isCustomerDialogOpen, setIsCustomerDialogOpen] = useState(false);
  const [isNoteDialogOpen, setIsNoteDialogOpen] = useState(false);
  const [driver, setDriver] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [selectedCartProduct, setSelectedCartProduct] = useState(null);
  const [note, setNote] = useState('');
  const [isPaymentMethodError, setIsPaymentMethodError] = useState(false);
  const [isReferenceCodeError, setIsReferenceCodeError] = useState(false);
  const [isCustomerError, setIsCustomerError] = useState(false);
  const [isCustomerDetail, setIsCustomerDetail] = useState(false);
  const [accesTokenCustomer, setAccesTokenCustomer] = useState('');
  const [pin, setPin] = useState();
  const [updatePin, setUpdatePin] = useState();
  const [verifyUpdatePin, setVerifyUpdatePin] = useState();
  const [notifPin, setNotifPin] = useState('');
  const [notifVerifyPin, setNotifVerifyPin] = useState('');
  const [dialogPin, setDialogPin] = useState(false);
  const [dialogUpdatePin, setDialogUpdatePin] = useState(false);
  const [isMinPin, setIsMinPin] = useState(false);
  const [dialogkantong, setDialogKantong] = useState(false);

  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState(null);
  const [isErrorTable, setIsErrorTable] = useState(false);

  const [orderTypes, setOrderTypes] = useState([]);
  const [selectedOrderType, setSelectedOrderType] = useState(null);
  const [isErrorOrderType, setIsErrorOrderType] = useState(false);
  const [discountType, setDiscountType] = useState('nominal');


  const [filteredPaymentMethods, setFilteredPaymentMethods] = useState([]);

  const [isExistCustomer, setIsExistCustomer] = useState(false);
  const [isReservation, setIsReservation] = useState(null);
  const [kantongStatus, setKantongStatus] = useState(false);
  const [openRemark, setOpenRemark] = useState(false);
  const [remark, setRemark] = useState('');
  const [isErrorRemark, setIsErrorRemark] = useState(false);
  const [errorEntertain, setErrorEntertain] = useState('');

  // ! update pin
  const handleUpdateNewPin = (event) => {
    if (event.target.value.length >= 6) {
      setUpdatePin(event.target.value);
    } else {
      setUpdatePin(event.target.value);
    }
  };

  const handleVerifyPin = (event) => {
    if (event.target.value === '') {
      setNotifVerifyPin('');
    } else if (event.target.value === updatePin) {
      setVerifyUpdatePin(event.target.value);
      setNotifVerifyPin('');
    } else {
      setNotifVerifyPin('password not match');
      setVerifyUpdatePin(event.target.value);
    }
  };

  useEffect(() => {
    if (updatePin?.length >= 6 && updatePin === verifyUpdatePin) {
      setIsMinPin(true);
    } else {
      setIsMinPin(false);
      setNotifPin('Minimal password 6 character');
    }
  }, [updatePin, verifyUpdatePin]);

  const handleUpdatePin = async () => {
    let params = {
      countryCode: 62,
      mPin: Number(updatePin),
      telpon: customer.phone,
      accessToken: accesTokenCustomer,
    };
    try {
      await updatePinCustomer(params);
      cekReservasi(isReservation);
    } catch (err) {
      console.error(err);
    } finally {
      setDialogUpdatePin(false);
      cekReservasi(isReservation);
    }
  };

  // ! discount type
  const handleChangeDiscountType = (v) => {
    setDiscountType(v)
  }

  //! cek data customer detail
  useEffect(() => {
    customer !== null ? setIsCustomerDetail(true) : setIsCustomerDetail(false);
  }, [customer, accesTokenCustomer]);

  useEffect(() => {
    const controller = new AbortController();

    const bootstrapAsync = async () => {
      try {
        if (selectedOrderType && selectedPaymentMethodId) {
          const { data } = await summarizeCart(
            {
              cartProducts: cartProducts.map((cartProduct) => ({
                id: cartProduct.id,
                quantity: cartProduct.qty,
              })),
              phone: customer?.phone,
              orderTypeId: selectedOrderType?.orderTypeId,
              paymentMethodId: selectedPaymentMethodId,
              discountCustomer: debouncedCustomerDiscount,
              externalGrandtotal: debouncedExternalGrandtotal,
              discountType
            },
            { signal: controller.signal }
          );

          setPriceSummary(data);
          setKantongStatus(data.kantong);
        }
        currentCartProductsTotalItems.current = getCartProductsTotalItems();
      } catch (error) {
        console.error(error);
      } finally {
        setIsSummarizing(false);
      }
    };

    selectedOrderType && selectedPaymentMethodId && setIsSummarizing(true);

    const timeoutID = setTimeout(bootstrapAsync, 1000);

    return () => {
      clearTimeout(timeoutID);
      controller.abort();
    };
  }, [
    cartProducts,
    getCartProductsTotalItems,
    selectedOrderType,
    selectedPaymentMethodId,
    debouncedCustomerDiscount,
    debouncedExternalGrandtotal,
    customer,
    discountType
  ]);

  //! is reservation
  const cekReservasi = (isReservation) => {
    const controller = new AbortController();

    setIsLoading(true);
    const bootstrapAsync = async () => {
      try {
        if (
          selectedOrderType &&
          selectedPaymentMethodId &&
          (isReservation !== null || isReservation === null)
        ) {
          const { data } = await summarizeCart(
            {
              cartProducts: cartProducts.map((cartProduct) => ({
                id: cartProduct.id,
                quantity: cartProduct.qty,
              })),
              orderTypeId: selectedOrderType?.orderTypeId,
              paymentMethodId: selectedPaymentMethodId,
              note: isReservation,
              accessToken: accesTokenCustomer,
              countryCode: 62,
              phoneNumber: customer.phone,
              discountCustomer: debouncedCustomerDiscount,
              externalGrandtotal: debouncedExternalGrandtotal,
              discountType
            },
            { signal: controller.signal }
          );

          if (data !== null) {
            setPriceSummary(data);
            setKantongStatus(data.kantong);
          } else {
            setDialogKantong(true);
          }
        }
        currentCartProductsTotalItems.current = getCartProductsTotalItems();
      } catch (error) {
        if (isReservation) {
          setDialogUpdatePin(true);
        }
        console.error(error);
        setIsSummarizing(false);
      } finally {
        setIsSummarizing(false);
        setIsLoading(false);
      }
    };

    selectedOrderType &&
      selectedPaymentMethodId &&
      isReservation &&
      setIsSummarizing(true);

    const timeoutID = setTimeout(bootstrapAsync, 1000);

    return () => {
      clearTimeout(timeoutID);
      controller.abort();
    };
  };

  useEffect(() => {
    cekReservasi(isReservation);
  }, [isReservation]);

  useEffect(() => {
    const controller = new AbortController();
    const bootstrapAsync = async () => {
      try {
        const { data } = await getPaymentMethods({ signal: controller.signal });

        setPaymentMethods(
          data.reduce(
            (previousPaymentMethod, currentPaymentMethod) =>
              previousPaymentMethod.concat(currentPaymentMethod.paymentMethods),
            []
          )
        );

        setFilteredPaymentMethods(
          data.reduce(
            (previousPaymentMethod, currentPaymentMethod) =>
              previousPaymentMethod.concat(currentPaymentMethod.paymentMethods),
            []
          )
        );
      } catch (error) {
        console.error(error);
      }
    };

    bootstrapAsync();

    return () => controller.abort();
  }, []);

  useEffect(() => {
    let clearNoteTimeoutID = null;

    if (!isNoteDialogOpen) {
      clearNoteTimeoutID = setTimeout(() => {
        setNote('');
        setSelectedCartProduct(null);
      }, theme.transitions.duration.leavingScreen);
    }

    return () => clearNoteTimeoutID && clearTimeout(clearNoteTimeoutID);
  }, [isNoteDialogOpen, theme.transitions.duration.leavingScreen]);

  const fetchOutletTables = async ({ outletId }) => {
    const { data: tables } = await getOutletTables({ outletId });

    setTables(tables.map((table) => ({ id: table.id, label: table.name })));
  };

  const fetchOutletOrderTypes = async ({ outletId }) => {
    const { data: orderTypes } = await getOutletOrderTypes({ outletId });

    setOrderTypes(orderTypes.filter((orderType) => orderType.status));
  };

  useEffect(() => {
    if (admin) {
      fetchOutletTables({ outletId: admin.outletId });
      fetchOutletOrderTypes({ outletId: admin.outletId });
    }
  }, [admin]);

  useEffect(() => {
    if (selectedOrderType) {
      setSelectedPaymentMethodId(null);

      if (selectedOrderType?.orderTypeId === 1) {
        setFilteredPaymentMethods(
          paymentMethods.filter(
            (paymentMethod) => paymentMethod.paymentMethodGroupId !== 4
          )
        );
      } else if (selectedOrderType?.orderTypeId === 2) {
        setFilteredPaymentMethods(
          paymentMethods.filter(
            (paymentMethod) => paymentMethod.paymentMethodGroupId === 4
          )
        );
      }
    }
  }, [paymentMethods, selectedOrderType]);

  const [notif, setNotif] = useState();
  const verifyMyPin = async () => {
    let params = {};
    params.accessToken = accesTokenCustomer;
    params.mPin = Number(pin);
    params.countryCode = 62;
    params.telpon = customer.phone;
    try {
      const data = await verifyPinCustomer(params);
      if (data.data.mPin === true) {
        handlePlaceOrderClick({ tableId: selectedTable.id });
      } else {
        setNotif('pin salah');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handlePlaceOrderClick = async ({ driver, referenceCode, tableId }) => {
    let dataSubmit = {
      paymentMethodId: selectedPaymentMethodId,
      orderType: selectedOrderType.orderTypeId,
      referenceCode: referenceCode,
      voucher: codeVoucher,
      tableId,
      externalGrandtotal: debouncedExternalGrandtotal,
      discountCustomer: debouncedCustomerDiscount,
      driver:
        selectedOrderType.orderTypeId === 2 && customer
          ? {
              name: customer.name,
              countryCode: '62',
              phoneNumber: customer.phone,
            }
          : [],
      customer: customer && {
        name: customer.name,
        countryCode: '62',
        phoneNumber: customer.phone,
      },
      products: cartProducts.map((cartProduct) => ({
        id: cartProduct.id,
        quantity: cartProduct.qty,
        note: cartProduct.note === '' ? null : cartProduct.note,
      })),
      note: isReservation ? 'Reservation' : null,
      accessToken: accesTokenCustomer,
      mPin: pin,
      // is_entertain: openRemark,
      remark: remark,
      discountType
    };

    try {
      setIsSummarizing(true);
      const {
        data: { invoiceId },
      } = await createInvoice(dataSubmit);
      navigate(`/histories/${invoiceId}`);

      openSnackbar();
      clearCart();
    } catch (error) {
      console.error(error);
      // setErrorEntertain(error.response.data.meta.message);
      window.alert(error.response.data.meta.message);
    } finally {
      setIsSummarizing(false);
    }
  };

  const handleKantong = () => {
    setDialogKantong(false);
    setIsReservation(null);
    cekReservasi();
  };

  useEffect(() => {
    setTimeout(() => {
      setErrorEntertain('');
    }, 1500);
  }, [errorEntertain]);

  return (
    <>
      {/* {errorEntertain !== '' && (
        <Alert
          sx={{ position: 'fixed', top: 10, right: 10, zIndex: 99999 }}
          severity="error"
        >
          {errorEntertain}
        </Alert>
      )} */}
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <IconButton
            edge="start"
            size="large"
            color="inherit"
            sx={{ ml: -0.25 }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: 'bold', flexGrow: 1, mx: 0.25 }}
          >
            Checkout
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="form"
        noValidate
        onSubmit={(event) => {
          event.preventDefault();
          !selectedPaymentMethodId && setIsPaymentMethodError(true);
          !referenceCode && setIsReferenceCodeError(true);
          !selectedTable && setIsErrorTable(true);
          !selectedOrderType && setIsErrorOrderType(true);
          !customer && setIsCustomerError(true);
          remark === '' && setIsErrorRemark(true);

          if (isReservation === null) {
            if (selectedOrderType && selectedPaymentMethodId && customer) {
              // if (selectedOrderType && selectedPaymentMethodId) {
              if (referenceCode) {
                handlePlaceOrderClick({ referenceCode, driver });
              } else if (selectedTable) {
                handlePlaceOrderClick({ tableId: selectedTable.id });
              }
            }
          } else {
            if (kantongStatus) {
              setDialogPin(true);
            } else {
              if (selectedOrderType && selectedPaymentMethodId && customer) {
                // if (selectedOrderType && selectedPaymentMethodId) {
                if (referenceCode) {
                  handlePlaceOrderClick({ referenceCode, driver });
                } else if (selectedTable) {
                  handlePlaceOrderClick({ tableId: selectedTable.id });
                }
              }
            }
          }
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            bgcolor: 'grey.100',
          }}
        >
          <Toolbar />
          <Box
            sx={{
              mt: 0.125,
              bgcolor: 'common.white',
              pb: 2,
            }}
          >
            <Typography
              sx={{
                mx: 2.25,
                mt: 1.875,
                fontSize: '0.9375rem',
                fontWeight: 'bold',
              }}
            >
              Pesanan
            </Typography>
            <Stack
              sx={{ mt: 0.5, mx: 2.25 }}
              divider={<Divider flexItem />}
              spacing={1.5}
            >
              {cartProducts.map((cartProduct) => (
                <Box key={cartProduct.id}>
                  <Typography sx={{ fontWeight: 'medium' }} variant="body2">
                    {cartProduct.name}
                  </Typography>
                  <Box
                    sx={{
                      mt: 0.25,
                    }}
                  >
                    {cartProduct.note && (
                      <Typography
                        sx={{
                          fontSize: '0.75rem',
                          color: 'grey.500',
                          mt: 0.25,
                        }}
                      >
                        Note: {cartProduct.note}
                      </Typography>
                    )}
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Typography variant="body2" component="span">
                        {formatNumberToCurrency(
                          cartProduct.price * cartProduct.qty
                        )}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            setSelectedCartProduct(cartProduct);
                            if (cartProduct.note) setNote(cartProduct.note);
                            setIsNoteDialogOpen(true);
                          }}
                          size="small"
                        >
                          <EditIcon
                            color="primary"
                            sx={{ fontSize: '1.125rem' }}
                          />
                        </IconButton>
                        <Box
                          sx={{
                            borderRadius: 1.25,
                            overflow: 'hidden',
                            display: 'flex',
                            alignItems: 'center',
                            bgcolor: 'primary.main',
                            ml: 1.125,
                          }}
                        >
                          {!isLoading ? (
                            <Button
                              sx={{
                                fontSize: '0.625rem',
                                width: '1.25rem',
                                py: 0.5,
                                fontWeight: 'medium',
                                minWidth: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                              }}
                              variant="contained"
                              disableElevation
                              onClick={() => {
                                if (cartProduct.qty === 1) {
                                  removeCartProduct(cartProduct.id);
                                  return;
                                }

                                updateCartProductQty(
                                  cartProduct.id,
                                  cartProduct.qty - 1
                                );
                                if (isReservation === 'Reservation') {
                                  cekReservasi('Reservation');
                                } else {
                                  cekReservasi();
                                }
                              }}
                            >
                              -
                            </Button>
                          ) : (
                            <Button
                              sx={{
                                fontSize: '0.625rem',
                                width: '1.25rem',
                                py: 0.5,
                                fontWeight: 'medium',
                                minWidth: 0,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                color: '#9E9D9D',
                              }}
                              variant="contained"
                              disableElevation
                            >
                              -
                            </Button>
                          )}

                          <Typography
                            sx={{
                              fontSize: '0.625rem',
                              width: '1.25rem',
                              textAlign: 'center',
                              fontWeight: 'bold',
                              color: 'common.white',
                            }}
                          >
                            {cartProduct.qty}
                          </Typography>
                          {!isLoading ? (
                            <Button
                              sx={{
                                fontSize: '0.625rem',
                                width: '1.25rem',
                                py: 0.5,
                                fontWeight: 'medium',
                                minWidth: 0,
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                              }}
                              variant="contained"
                              disableElevation
                              onClick={() => {
                                updateCartProductQty(
                                  cartProduct.id,
                                  cartProduct.qty + 1
                                );
                                if (isReservation === 'Reservation') {
                                  cekReservasi('Reservation');
                                } else {
                                  cekReservasi();
                                }
                              }}
                            >
                              +
                            </Button>
                          ) : (
                            <Button
                              sx={{
                                fontSize: '0.625rem',
                                width: '1.25rem',
                                py: 0.5,
                                fontWeight: 'medium',
                                minWidth: 0,
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                color: '#9E9D9D',
                              }}
                              variant="contained"
                              disableElevation
                            >
                              +
                            </Button>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              ))}
            </Stack>
          </Box>
          {priceSummary && (
            <Box sx={{ bgcolor: 'common.white', mt: 1.25, pb: 2.375 }}>
              <Typography
                sx={{
                  fontSize: '0.9375rem',
                  mt: 1.875,
                  mx: 2.25,
                  fontWeight: 'bold',
                }}
              >
                Detail Pembayaran
              </Typography>

              <Stack sx={{ mt: 0.5 }}>
                {priceSummary.details.map((priceDetail, index) => (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mx: 2.25,
                    }}
                    key={index}
                  >
                    <Typography variant="caption" sx={{ color: 'grey.500' }}>
                      {priceDetail.label}
                    </Typography>
                    <Typography variant="caption">
                      {formatNumberToCurrency(priceDetail.value)}
                    </Typography>
                  </Box>
                ))}

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mx: 2.25,
                  }}
                >
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Total
                  </Typography>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    {formatNumberToCurrency(priceSummary.total)}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          )}
          <Box
            sx={{
              bgcolor: 'common.white',
              px: 2.25,
              py: 2.25,
              mt: 1,
            }}
          >
            <Typography sx={{ fontSize: '0.9375rem', fontWeight: 'bold' }}>
              Tipe Pesanan*
            </Typography>
            <FormControl
              fullWidth
              variant="standard"
              error={isErrorOrderType}
              sx={{ mt: 1 }}
            >
              <Grid container columnSpacing={2.25} rowSpacing={0.5}>
                {orderTypes.map((orderType) => (
                  <Grid item xs={4} key={orderType.id}>
                    <Button
                      fullWidth
                      variant={
                        orderType.id === selectedOrderType?.id
                          ? 'contained'
                          : 'outlined'
                      }
                      disableElevation={orderType.id === selectedOrderType?.id}
                      sx={[
                        {
                          textAlign: 'center',
                          typography: 'caption',
                          textTransform: 'capitalize',
                          height: 1,
                          minHeight: '2.875rem',
                          fontWeight: 'medium',
                        },
                        orderType.id !== selectedOrderType?.id && {
                          color: 'common.black',
                        },
                        isErrorOrderType && {
                          borderColor: 'error.main',
                        },
                      ]}
                      onClick={() => {
                        setIsErrorOrderType(false);
                        setSelectedOrderType(orderType);
                        setIsReservation(null);
                        setPriceSummary(null);
                        setExternalGrantotal('');
                        setCustomerDiscount('');
                      }}
                    >
                      {orderType.name}
                    </Button>
                  </Grid>
                ))}

                {isErrorOrderType && (
                  <Grid item xs={12}>
                    <FormHelperText sx={{ m: 0 }}>
                      Please select an order Type
                    </FormHelperText>
                  </Grid>
                )}
              </Grid>
            </FormControl>

            {selectedOrderType && (
              <Typography
                sx={{ fontSize: '0.9375rem', fontWeight: 'bold', mt: 2.25 }}
              >
                Metode Pembayaran*
              </Typography>
            )}
            {selectedOrderType && (
              <>
                <FormControl
                  fullWidth
                  variant="standard"
                  error={isPaymentMethodError}
                  sx={{ mt: 1 }}
                >
                  <Grid container columnSpacing={2.25} rowSpacing={0.5}>
                    {filteredPaymentMethods.map((paymentMethod) => (
                      <Grid item xs={4} key={paymentMethod.id}>
                        <Button
                          fullWidth
                          variant={
                            paymentMethod.id === selectedPaymentMethodId
                              ? 'contained'
                              : 'outlined'
                          }
                          disableElevation={
                            paymentMethod.id === selectedPaymentMethodId
                          }
                          sx={[
                            {
                              textAlign: 'center',
                              typography: 'caption',
                              height: 1,
                              minHeight: '2.875rem',
                              fontWeight: 'medium',
                            },
                            paymentMethod.id !== selectedPaymentMethodId && {
                              color: 'common.black',
                            },
                            isPaymentMethodError && {
                              borderColor: 'error.main',
                            },
                          ]}
                          onClick={() => {
                            setIsPaymentMethodError(false);

                            setSelectedPaymentMethodId(paymentMethod.id);

                            paymentMethod.code === 'manual_entertain'
                              ? setOpenRemark(true)
                              : setOpenRemark(false);
                          }}
                        >
                          {paymentMethod.name}
                        </Button>
                      </Grid>
                    ))}

                    {isPaymentMethodError && (
                      <Grid item xs={12}>
                        <FormHelperText sx={{ mt: 0 }}>
                          Please select a payment method
                        </FormHelperText>
                      </Grid>
                    )}
                  </Grid>
                </FormControl>

                {/* //!-- Rerservasi Tombol */}
                {selectedOrderType?.orderTypeId === 1 && (
                  <Grid container columns={12} sx={{ mt: 0.5 }} spacing={2}>
                    <Grid item xs={4}>
                      <Button
                        fullWidth
                        disabled={!isCustomerDetail}
                        variant={isReservation ? 'contained' : 'outlined'}
                        sx={[
                          {
                            textAlign: 'center',
                            typography: 'caption',
                            height: 1,
                            minHeight: '2.875rem',
                            fontWeight: 'medium',
                          },
                          !isReservation && {
                            color: 'common.black',
                          },
                        ]}
                        onClick={() => {
                          if (isReservation) {
                            setIsReservation(null);
                            cekReservasi(null);
                          } else {
                            setIsReservation('Reservation');
                            cekReservasi();
                          }
                        }}
                      >
                        Reservation
                      </Button>
                    </Grid>

                    {/* remark canceled */}
                    {/* <Grid item xs={4}>
                      <Button
                        fullWidth
                        // variant={openRemark ? 'contained' : 'outlined'}
                        // disableElevation={selectedPaymentMethodId} 
                          Number(1) === selectedPaymentMethodId
                            ? 'contained'
                            : 'outlined'
                        }
                        disableElevation={Number(1) === selectedPaymentMethodId}
                        sx={[
                          {
                            textAlign: 'center',
                            typography: 'caption',
                            height: 1,
                            minHeight: '2.875rem',
                            fontWeight: 'medium',
                          },
                          Number(1) !== selectedPaymentMethodId && {
                            color: 'common.black',
                          },
                        ]}
                        onClick={() => {
                          setOpenRemark(true);
                          // setIsPaymentMethodError(false);
                          setSelectedPaymentMethodId(Number(1));
                        }}
                      >
                        Entertain
                      </Button>
                    </Grid> */}
                  </Grid>
                )}
              </>
            )}

            {openRemark && (
              <Box
                sx={{
                  bgcolor: 'common.white',
                }}
              >
                <FormControl fullWidth>
                  <TextField
                    label={'Remark *'}
                    onWheel={() => document.activeElement.blur()}
                    InputLabelProps={{
                      shrink: true,
                      sx: {
                        typography: 'caption',
                        zIndex: 0,
                      },
                    }}
                    InputProps={{
                      sx: {
                        typography: 'caption',
                      },
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    }}
                    sx={{
                      mt: 2.625,
                      '& > div > fieldset > legend > span': {
                        paddingLeft: 0,
                        color: 'red',
                      },
                    }}
                    error={isErrorRemark}
                    value={remark}
                    onChange={(event) => {
                      setRemark(event.target.value);
                      setIsErrorRemark(false);
                    }}
                  />
                </FormControl>
              </Box>
            )}

            {selectedOrderType?.orderTypeId === 1 && (
              <Autocomplete
                disablePortal
                options={tables}
                fullWidth
                sx={{ mt: 2.625 }}
                value={selectedTable}
                onChange={(event, newValue) => {
                  setSelectedTable(newValue);
                  newValue ? setIsErrorTable(false) : setIsErrorTable(true);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={isErrorTable}
                    label="Table"
                    helperText={isErrorTable && 'Please choose a table'}
                  />
                )}
              />
            )}
            {selectedOrderType?.orderTypeId === 2 && (
              <FormControl fullWidth error={isReferenceCodeError}>
                <TextField
                  label="Reference Code"
                  placeholder="G-663"
                  required
                  error={isReferenceCodeError}
                  InputLabelProps={{
                    shrink: true,
                    sx: {
                      typography: 'caption',
                      zIndex: 0,
                    },
                  }}
                  InputProps={{
                    sx: {
                      typography: 'caption',
                    },
                  }}
                  sx={{
                    mt: 2.625,
                    '& > div > fieldset > legend > span': {
                      paddingLeft: 0,
                    },
                  }}
                  value={referenceCode}
                  onChange={(event) => {
                    setIsReferenceCodeError(false);
                    setReferenceCode(event.target.value);
                  }}
                />
                {isReferenceCodeError && (
                  <FormHelperText>Please enter a reference code</FormHelperText>
                )}
              </FormControl>
            )}
          </Box>

          <Box
            sx={{
              bgcolor: 'common.white',
              px: 2.25,
              py: 2.25,
              mt: 1,
            }}
          >
            <Grid container columns={12} spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <TextField
                    label={
                      selectedOrderType?.orderTypeId === 1
                        ? 'Discount'
                        : 'External Grand Total'
                    }
                    placeholder="0"
                    type="number"
                    onWheel={() => document.activeElement.blur()}
                    InputLabelProps={{
                      shrink: true,
                      sx: {
                        typography: 'caption',
                        zIndex: 0,
                      },
                    }}
                    InputProps={{
                      sx: {
                        typography: 'caption',
                      },
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                      startAdornment: <InputAdornment position="start">
                        <Select
                          labelId="discount_type"
                          id="discount_type"
                          value={discountType}
                          label=""
                          size='small'
                          sx={{
                            '& .MuiSelect-select': {
                              backgroundColor: '#ccc',
                              paddingTop: '2px',
                              paddingRight: '0px',
                              paddingBottom: '2px',
                              fontSize: '12px',
                            },
                          }}
                          onChange={(v) => handleChangeDiscountType( v.target.value)}
                        >
                          <MenuItem value="nominal">Rp</MenuItem>
                          <MenuItem value="percentage">%</MenuItem>
                        </Select>
                      </InputAdornment>,
                    }}
                    sx={{
                      mt: 2.625,
                      '& > div > fieldset > legend > span': {
                        paddingLeft: 0,
                      },
                    }}
                    value={
                      selectedOrderType?.orderTypeId === 1
                        ? customerDiscount
                        : externalGrandtotal
                    }
                    onChange={(event) => {
                      selectedOrderType?.orderTypeId === 1
                        ? setCustomerDiscount(event.target.value)
                        : setExternalGrantotal(event.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                {selectedOrderType?.orderTypeId === 1 && (
                  <FormControl fullWidth>
                    <TextField
                      label="Kode Voucher"
                      placeholder="kode voucher "
                      // type="number"
                      InputLabelProps={{
                        shrink: true,
                        sx: {
                          typography: 'caption',
                          zIndex: 0,
                        },
                      }}
                      InputProps={{
                        sx: {
                          typography: 'caption',
                        },
                      }}
                      sx={{
                        mt: 2.625,
                        '& > div > fieldset > legend > span': {
                          paddingLeft: 0,
                        },
                      }}
                      value={codeVoucher}
                      onChange={(event) => {
                        setCodeVoucher(event.target.value);
                      }}
                    />
                  </FormControl>
                )}
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ mt: 1.25, bgcolor: 'common.white', mb: 11.25 }}>
            {/* {selectedOrderType?.orderTypeId === 2 && (
              <>
                <Typography
                  sx={{
                    fontSize: '0.9375rem',
                    mt: 1.875,
                    mx: 2.25,
                    fontWeight: 'bold',
                  }}
                >
                  Driver Detail
                </Typography>
                <Button
                  fullWidth
                  endIcon={
                    <ArrowForwardIosIcon
                      sx={{
                        width: '1.25rem',
                        height: '1.25rem',
                        color: 'common.black',
                      }}
                    />
                  }
                  sx={[
                    {
                      justifyContent: 'space-between',
                      px: 2.25,
                      borderRadius: 0,
                      mt: 0.625,
                      fontWeight: 'regular',
                    },
                    driver
                      ? { color: 'common.black' }
                      : {
                          color: 'grey.500',
                        },
                  ]}
                  onClick={() => setIsDriverDialogOpen(true)}
                >
                  {driver
                    ? `${driver.name} (+62${driver.phone})`
                    : 'Input Driver Detail'}
                </Button>
              </>
            )} */}

            <Typography
              sx={{
                fontSize: '0.9375rem',
                mt: 1.875,
                mx: 2.25,
                fontWeight: 'bold',
              }}
            >
              {selectedOrderType?.orderTypeId === 2
                ? 'Driver Detail*'
                : 'Customer Detail*'}
            </Typography>
            <Button
              fullWidth
              endIcon={
                <ArrowForwardIosIcon
                  sx={{
                    width: '1.25rem',
                    height: '1.25rem',
                    color: 'common.black',
                  }}
                />
              }
              sx={[
                {
                  justifyContent: 'space-between',
                  px: 2.25,
                  borderRadius: 0,
                  mt: 0.625,
                  mb: 2.5625,
                  fontWeight: 'regular',
                },
                customer
                  ? { color: 'common.black' }
                  : isCustomerError
                  ? { color: 'error.main' }
                  : {
                      color: 'grey.500',
                    },
              ]}
              onClick={() => setIsCustomerDialogOpen(true)}
            >
              {customer
                ? `${customer.name} (+62${customer.phone})`
                : selectedOrderType?.orderTypeId === 2
                ? 'Input Driver Detail'
                : 'Input Customer Detail'}{' '}
            </Button>
          </Box>
        </Box>

        <Container
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            px: 2.5,
            bgcolor: 'background.default',
          }}
          maxWidth="false"
        >
          <LoadingButton
            variant="contained"
            sx={{
              typography: 'h6',
              width: 1,
              borderRadius: 2.5,
              height: '3.125rem',
              my: 1.875,
            }}
            disableElevation
            loading={isSummarizing}
            type="submit"
          >
            {priceSummary
              ? `Place Order (${formatNumberToCurrency(priceSummary.total)})`
              : 'Submit'}
          </LoadingButton>
        </Container>
      </Box>
      <Dialog fullScreen open={isDriverDialogOpen}>
        <DriverDetail
          driver={driver}
          onSubmitClick={(driver) => {
            setDriver(driver);
            setIsDriverDialogOpen(false);
          }}
          onBackClick={() => setIsDriverDialogOpen(false)}
        />
      </Dialog>
      <Dialog fullScreen open={isCustomerDialogOpen}>
        <CustomerDetail
          typeOrder={selectedOrderType?.orderTypeId}
          setAccesTokenCustomer={setAccesTokenCustomer}
          customer={customer}
          onSubmitClick={(customer) => {
            setCustomer(customer);
            setIsCustomerDialogOpen(false);
          }}
          onBackClick={() => setIsCustomerDialogOpen(false)}
          isExist={isExistCustomer}
          setIsExist={setIsExistCustomer}
        />
      </Dialog>
      <Dialog
        open={isNoteDialogOpen}
        onClose={() => setIsNoteDialogOpen(false)}
        PaperProps={{ sx: { minWidth: '20.625rem', width: '80%' } }}
      >
        <DialogTitle
          sx={{
            typography: 'body2',
            fontWeight: 'bold',
            pt: 2.25,
            px: 2.5,
            pb: 0.625,
          }}
        >
          Catatan
        </DialogTitle>
        <DialogContent sx={{ pb: 0, px: 2.5 }}>
          <TextField
            fullWidth
            InputProps={{
              sx: {
                typography: 'caption',
                color: 'grey.700',
              },
            }}
            multiline
            variant="standard"
            value={note}
            onChange={(event) => setNote(event.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ pb: 1.875, px: 2.5, pt: 1.625 }}>
          <Button
            autoFocus
            variant="contained"
            fullWidth
            disableElevation
            onClick={() => {
              updateCartProductNote(selectedCartProduct.id, note);
              setIsNoteDialogOpen(false);
            }}
            sx={{
              typography: 'body2',
              fontWeight: 600,
              py: 1.25,
              borderRadius: 2.5,
            }}
          >
            Simpan
          </Button>
        </DialogActions>
      </Dialog>

      {/* dialog pin */}
      <Dialog
        open={dialogPin && kantongStatus}
        onClose={() => setDialogPin(false)}
        PaperProps={{
          sx: {
            minWidth: '70%',
            width: '70%',
          },
        }}
      >
        <DialogTitle
          sx={{
            typography: 'body2',
            fontWeight: 'bold',
            pt: 2.25,
            px: 2.5,
            pb: 0.625,
          }}
        >
          Masukkan Pin
        </DialogTitle>
        <DialogContent sx={{ pb: 0, px: 2.5 }}>
          <Typography sx={{ color: 'red' }}>{notif && notif}</Typography>
          <TextField
            autoFocus
            type="password"
            fullWidth
            InputProps={{
              sx: {
                typography: 'caption',
                color: 'grey.700',
              },
            }}
            variant="standard"
            value={pin}
            onChange={(event) => {
              if (event.target.value.length >= 6) {
                setIsMinPin(true);
                setPin(event.target.value);
              } else {
                setIsMinPin(false);
                setPin(event.target.value);
              }
            }}
          />
        </DialogContent>
        <DialogActions sx={{ pb: 1.875, px: 2.5, pt: 1.625 }}>
          <Button
            disabled={!isMinPin}
            autoFocus
            variant="outlined"
            fullWidth
            disableElevation
            onClick={verifyMyPin}
            sx={{
              typography: 'body2',
              fontWeight: 600,
              py: 1,
              borderRadius: 2.5,
              color: '#000000',
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* dialog update pin */}
      <Dialog
        open={dialogUpdatePin}
        onClose={() => setDialogUpdatePin(false)}
        PaperProps={{
          sx: {
            minWidth: '70%',
            width: '70%',
          },
        }}
      >
        <DialogTitle
          sx={{
            typography: 'body2',
            fontWeight: 'bold',
            pt: 2.25,
            px: 2.5,
            pb: 0.625,
          }}
        >
          Buat Pin Baru
        </DialogTitle>
        <DialogContent sx={{ pb: 0, px: 2.5 }}>
          <TextField
            type="password"
            label="password"
            fullWidth
            InputProps={{
              sx: {
                typography: 'caption',
                color: 'grey.700',
              },
            }}
            variant="standard"
            value={pin}
            onChange={handleUpdateNewPin}
          />
          {updatePin?.length < 6 && (
            <Typography sx={{ color: '#9E9D9D', fontSize: '12px' }}>
              {notifPin}
            </Typography>
          )}
          <TextField
            type="password"
            label="verify password"
            fullWidth
            InputProps={{
              sx: {
                typography: 'caption',
                color: 'grey.700',
              },
            }}
            variant="standard"
            value={pin}
            onChange={handleVerifyPin}
            sx={{ mt: 2 }}
          />

          <Typography sx={{ color: '#F33A3A', fontSize: '12px' }}>
            {notifVerifyPin}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ pb: 1.875, px: 2.5, pt: 1.625 }}>
          <Button
            disabled={!isMinPin}
            autoFocus
            variant="outlined"
            fullWidth
            disableElevation
            onClick={handleUpdatePin}
            sx={{
              typography: 'body2',
              fontWeight: 600,
              py: 1,
              borderRadius: 2.5,
              color: '#000000',
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={dialogkantong}
        onClose={() => setDialogKantong(false)}
        PaperProps={{
          sx: {
            minWidth: '70%',
            width: '70%',
          },
        }}
      >
        <DialogTitle
          sx={{
            typography: 'body2',
            fontWeight: 'bold',
            pt: 2.25,
            px: 2.5,
            pb: 0.625,
          }}
        >
          Notifikasi !!!
        </DialogTitle>
        <DialogContent sx={{ pb: 0, px: 2.5 }}>
          <Typography sx={{ color: '#FF0000', fontSize: '12px' }}>
            Tida bisa membuat orderan reservasi
          </Typography>
        </DialogContent>
        <DialogActions sx={{ pb: 1.875, px: 2.5, pt: 1.625 }}>
          <Button
            // disabled={!isMinPin}
            autoFocus
            variant="outlined"
            fullWidth
            disableElevation
            onClick={handleKantong}
            sx={{
              typography: 'body2',
              fontWeight: 600,
              py: 1,
              borderRadius: 2.5,
              color: '#000000',
            }}
          >
            Kembali
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Checkout;
