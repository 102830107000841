import { ArrowBackIosNew as ArrowBackIosNewIcon } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getHistory } from '../api/history';
import { formatNumberToCurrency } from '../utils/currency';

const HistoryDetail = () => {
  const navigate = useNavigate();
  const { historyId } = useParams();
  const [history, setHistory] = useState();

  useEffect(() => {
    const controller = new AbortController();

    const bootstrapAsync = async () => {
      try {
        const { data } = await getHistory(
          { id: historyId },
          { signal: controller.signal }
        );
        setHistory(data);
      } catch (error) {
        console.error(error);
      }
    };

    bootstrapAsync();

    return () => controller.abort();
  }, [historyId]);

  return (
    <>
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <IconButton
            edge="start"
            size="large"
            color="inherit"
            sx={{ ml: -0.25 }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: 'bold', flexGrow: 1 }}
          >
            History Detail
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
          bgcolor: 'grey.100',
        }}
      >
        <Toolbar />
        {history && (
          <>
            <Box sx={{ bgcolor: 'common.white', pb: 2.5 }}>
              <Typography sx={{ mt: 2.5, mx: 2.5, fontSize: '0.6875rem' }}>
                #INV-{historyId}
              </Typography>
              <Grid container rowSpacing={1.375} sx={{ px: 2.5, pt: 2.5 }}>
                <Grid container item>
                  <Grid
                    item
                    xs
                    sx={{ fontSize: '0.8125rem', color: 'grey.500' }}
                  >
                    Payment Method
                  </Grid>
                  <Grid item sx={{ typography: 'caption' }}>
                    {history.thirdPartyInvoice?.paymentMethod?.name || '-'}
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid
                    item
                    xs
                    sx={{ fontSize: '0.8125rem', color: 'grey.500' }}
                  >
                    Date
                  </Grid>
                  <Grid item sx={{ typography: 'caption' }}>
                    {`${new Date(history.createdAt).toLocaleDateString('id', {
                      dateStyle: 'medium',
                    })}, ${new Date(history.createdAt).toLocaleTimeString(
                      'en-US',
                      {
                        hour12: false,
                        timeStyle: 'short',
                      }
                    )}`}
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid
                    item
                    xs
                    sx={{ fontSize: '0.8125rem', color: 'grey.500' }}
                  >
                    Reference Code
                  </Grid>
                  <Grid item sx={{ typography: 'caption' }}>
                    {history.thirdPartyInvoice?.referenceCode || '-'}
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid
                    item
                    xs
                    sx={{ fontSize: '0.8125rem', color: 'grey.500' }}
                  >
                    Status
                  </Grid>
                  <Grid item sx={{ typography: 'caption' }}>
                    {history.status === 200
                      ? 'Paid'
                      : history.status === 201
                      ? 'Finished'
                      : history.status === 100
                      ? 'Pending'
                      : '-'}
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid
                    item
                    xs
                    sx={{ fontSize: '0.8125rem', color: 'grey.500' }}
                  >
                    Grand Total
                  </Grid>
                  <Grid
                    item
                    sx={{
                      typography: 'caption',
                      fontWeight: 'bold',
                      color: 'primary.main',
                    }}
                  >
                    {formatNumberToCurrency(history.total)}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            {(history.thirdPartyInvoice?.driver ||
              history.thirdPartyInvoice?.customer) && (
              <Box sx={{ mt: 1.25, bgcolor: 'common.white', pb: 1.875 }}>
                {history.thirdPartyInvoice?.driver && (
                  <>
                    <Typography
                      sx={{
                        fontSize: '0.9375rem',
                        mt: 1.875,
                        mx: 2.25,
                        fontWeight: 'bold',
                      }}
                    >
                      Driver Detail
                    </Typography>
                    <Typography sx={{ mx: 2.25, mt: 1 }} variant="body2">
                      {history.thirdPartyInvoice?.driver?.name} (
                      {`+${history.thirdPartyInvoice?.driver?.countryCode}${history.thirdPartyInvoice?.driver?.phoneNumber}`}
                      )
                    </Typography>
                  </>
                )}
                {history.thirdPartyInvoice?.customer && (
                  <>
                    <Typography
                      sx={{
                        fontSize: '0.9375rem',
                        mt: 1.875,
                        mx: 2.25,
                        fontWeight: 'bold',
                      }}
                    >
                      Customer Detail
                    </Typography>
                    <Typography sx={{ mx: 2.25, mt: 1 }} variant="body2">
                      {history.thirdPartyInvoice.customer.name} (
                      {`+${history.thirdPartyInvoice.customer.countryCode}${history.thirdPartyInvoice.customer.phoneNumber}`}
                      )
                    </Typography>
                  </>
                )}
              </Box>
            )}
            <Box
              sx={{
                mt: 1.25,
                bgcolor: 'common.white',
              }}
            >
              <Typography
                sx={{
                  mx: 2.25,
                  mt: 1.875,
                  fontSize: '0.9375rem',
                  fontWeight: 'bold',
                }}
              >
                Pesanan
              </Typography>
              <Stack
                sx={{ mt: 0.5, mx: 2.25, mb: 2 }}
                divider={<Divider flexItem />}
                spacing={1.5}
              >
                {history.orders.map((order) => (
                  <Grid container key={order.id}>
                    <Grid item xs>
                      <Typography sx={{ fontWeight: 'medium' }} variant="body2">
                        {order.name}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'flex-end',
                          mt: 0.25,
                        }}
                      >
                        <Box>
                          {order.note && (
                            <Typography
                              sx={{
                                fontSize: '0.75rem',
                                color: 'grey.500',
                              }}
                            >
                              Note: {order.note}
                            </Typography>
                          )}
                          <Typography variant="body2" component="span">
                            {formatNumberToCurrency(order.price)}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item>
                      <Typography sx={{ fontWeight: 'medium' }} variant="body2">
                        x{order.quantity}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Stack>
            </Box>
            <Box sx={{ bgcolor: 'common.white', my: 1.25, pb: 2.375 }}>
              <Typography
                sx={{
                  fontSize: '0.9375rem',
                  mt: 1.875,
                  mx: 2.25,
                  fontWeight: 'bold',
                }}
              >
                Detail Pembayaran
              </Typography>
              <Stack sx={{ mt: 0.5 }}>
                {history.details.map((detail, index) => (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mx: 2.25,
                    }}
                    key={index}
                  >
                    <Typography variant="caption" sx={{ color: 'grey.500' }}>
                      {detail.label}
                    </Typography>
                    <Typography variant="caption">
                      {formatNumberToCurrency(detail.value)}
                    </Typography>
                  </Box>
                ))}
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mx: 2.25,
                  }}
                >
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    Total
                  </Typography>
                  <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                    {formatNumberToCurrency(history.total)}
                  </Typography>
                </Box>
              </Stack>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default HistoryDetail;
