import { useEffect, useRef } from 'react';

import { Box } from '@mui/material';
import lottie from 'lottie-web';

import animationData from '../assets/animations/404-error.json';

const NotFound = () => {
  const element = useRef(null);

  useEffect(() => {
    if (element.current) {
      lottie.loadAnimation({
        animationData,
        container: element.current,
      });
    }
  }, []);

  return <Box ref={element} sx={{ height: '100vh' }} />;
};

export default NotFound;
