import { createContext, useState } from 'react';

export const SnackbarContext = createContext({
  isOpen: false,
  open: () => {},
  close: () => {},
});

export const SnackbarContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return (
    <SnackbarContext.Provider
      value={{
        isOpen,
        open,
        close,
      }}
    >
      {children({ isOpen, open, close })}
    </SnackbarContext.Provider>
  );
};
