import { posAxios } from '../utils/api';

export const logIn = ({ emailAddress, password }) =>
  posAxios.post('auth/login', { email: emailAddress, password });

export const logOut = () => posAxios.post('auth/logout');

export const getAuthDetail = (config) => posAxios.get('auth/detail', config);

export const fetchOTP = (data) => posAxios.post('customer/get-otp', data);

export const submitOTP = (data) => posAxios.post('customer/login', data);
