import { useContext, useEffect, useRef } from 'react';
import { Navigate, useLocation, Outlet } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { Box } from '@mui/material';
import lottie from 'lottie-web';

import animationData from '../assets/animations/loading.json';

const RequireAuth = () => {
  const { admin, checkAccessToken, accessToken } = useContext(AuthContext);
  const location = useLocation();
  const element = useRef(null);

  useEffect(() => {
    const controller = new AbortController();

    const bootstrapAsync = async () => {
      try {
        await checkAccessToken({ signal: controller.signal });
      } catch (error) {
        console.error(error);
      }
    };

    if (accessToken && !admin) {
      bootstrapAsync();
      return () => controller.abort();
    }
  }, [admin, checkAccessToken, accessToken]);

  useEffect(() => {
    if (element.current) {
      lottie.loadAnimation({
        animationData,
        container: element.current,
      });
    }
  }, []);

  if (admin) {
    return <Outlet />;
  }

  if (!accessToken) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Box ref={element} sx={{ height: '100vh' }} />;
};

export default RequireAuth;
