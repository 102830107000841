import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.withCredentials = true;

export const posAxios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

posAxios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

    if (config.data) config.data = decamelizeKeys(config.data);
    if (config.params) config.params = decamelizeKeys(config.params);

    return config;
  },
  (error) => Promise.reject(error)
);

posAxios.interceptors.response.use(
  (response) => camelizeKeys(response.data),
  (error) => Promise.reject(error)
);
