import {
  ArrowBackIosNew as ArrowBackIosNewIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Stack,
  IconButton,
  Paper,
  Toolbar,
  Typography,
  Dialog,
  Container,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Skeleton,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useState, useEffect, useContext } from 'react';
import {
  Link as RouterLink,
  useLocation,
  useParams,
  useNavigate,
} from 'react-router-dom';
import { getProductsBySubcategory } from '../api/product';
import { CartContext } from '../contexts/CartContext';
import { formatNumberToCurrency } from '../utils/currency';

const ProductCard = ({ product, cartProduct, onAddClick, onNoteClick }) => {
  const { updateCartProductQty, removeCartProduct } = useContext(CartContext);
  const [isImageLoading, setIsImageLoading] = useState(true);

  return (
    <Paper
      key={product.id}
      sx={{
        display: 'flex',
        borderRadius: 2.5,
        overflow: 'hidden',
      }}
      elevation={3}
    >
      {isImageLoading && (
        <Skeleton
          animation="wave"
          variant="rectangle"
          sx={{ height: '7.5rem', width: '7.5rem' }}
        />
      )}
      <Box
        component="img"
        sx={[
          { objectFit: 'cover', height: '7.5rem', aspectRatio: '1' },
          isImageLoading && { display: 'none' },
        ]}
        src={product.imageUrl}
        alt=""
        onLoad={() => setIsImageLoading(false)}
      />

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={{
            fontWeight: 'bold',
            mt: 1.375,
            ml: 1.75,
            mr: 1.375,
          }}
          variant="caption"
          component="div"
        >
          {product.name}
        </Typography>
        <Box sx={{ flex: 1, mt: 0.625, ml: 1.75, mr: 1.375 }}>
          <Typography
            sx={{
              fontSize: '0.625rem',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: '2',
              overflow: 'hidden',
            }}
          >
            {product.description}
          </Typography>
          {cartProduct && cartProduct.note && (
            <Typography
              sx={{
                fontSize: '0.625rem',
                color: 'grey.500',
              }}
            >
              Note: {cartProduct.note}
            </Typography>
          )}
        </Box>
        <Box
          sx={[
            {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              ml: 1.75,
              mr: 1.375,
            },
            cartProduct ? { mb: 1.125 } : { mb: 1.25 },
          ]}
        >
          <Typography
            sx={{ fontWeight: 'bold' }}
            color="primary.dark"
            variant="caption"
          >
            {formatNumberToCurrency(product.price)}
          </Typography>
          {!cartProduct ? (
            <Button
              disabled={!product.status}
              variant="contained"
              disableElevation
              sx={{
                fontSize: '0.625rem',
                px: 2.625,
                py: 0.5625,
                fontWeight: 'medium',
              }}
              onClick={onAddClick}
            >
              Tambah
            </Button>
          ) : (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IconButton onClick={onNoteClick} size="small">
                <EditIcon color="primary" sx={{ fontSize: '1.125rem' }} />
              </IconButton>
              <Box
                sx={{
                  borderRadius: 1.25,
                  overflow: 'hidden',
                  display: 'flex',
                  alignItems: 'center',
                  bgcolor: 'primary.main',
                  ml: 1.125,
                }}
              >
                <Button
                  sx={{
                    fontSize: '0.625rem',
                    width: '1.25rem',
                    py: 0.5625,
                    fontWeight: 'medium',
                    minWidth: 0,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    if (cartProduct.qty === 1) {
                      removeCartProduct(product.id);
                      return;
                    }

                    updateCartProductQty(product.id, cartProduct.qty - 1);
                  }}
                >
                  -
                </Button>
                <Typography
                  sx={{
                    fontSize: '0.625rem',
                    width: '1.25rem',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    color: 'common.white',
                  }}
                >
                  {cartProduct.qty}
                </Typography>
                <Button
                  sx={{
                    fontSize: '0.625rem',
                    width: '1.25rem',
                    py: 0.5,
                    fontWeight: 'medium',
                    minWidth: 0,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                  variant="contained"
                  disableElevation
                  onClick={() =>
                    updateCartProductQty(product.id, cartProduct.qty + 1)
                  }
                >
                  +
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

const Menu = () => {
  const { subcategoryId } = useParams();
  const { state } = useLocation();
  const subcategory = state && state.subcategory;
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [note, setNote] = useState('');
  const [isImageLoading, setIsImageLoading] = useState(true);
  const {
    cartProducts,
    addCartProduct,
    getCartProductsTotalPrice,
    getCartProductsTotalItems,
    updateCartProductNote,
  } = useContext(CartContext);
  const theme = useTheme();

  useEffect(() => {
    const controller = new AbortController();

    const bootstrapAsync = async () => {
      try {
        const { data } = await getProductsBySubcategory(
          { id: subcategoryId },
          { signal: controller.signal }
        );
        if (data.length > 0) setProducts(data);
        else setProducts(null);
      } catch (error) {
        console.error(error);
      }
    };

    if (subcategory) {
      bootstrapAsync();

      return () => controller.abort();
    }
  }, [subcategory, subcategoryId]);

  useEffect(() => {
    let clearNoteTimeoutID = null;

    if (!isDialogOpen) {
      clearNoteTimeoutID = setTimeout(() => {
        setNote('');
        setSelectedProduct(null);
      }, theme.transitions.duration.leavingScreen);
    }

    return () => clearNoteTimeoutID && clearTimeout(clearNoteTimeoutID);
  }, [isDialogOpen, theme.transitions.duration.leavingScreen]);

  // useEffect(() => {
  //   let clearSelectedProductTimeoutID = null;

  //   if (!isDrawerOpen) {
  //     clearSelectedProductTimeoutID = setTimeout(
  //       () => setSelectedProduct(null),
  //       theme.transitions.duration.leavingScreen
  //     );
  //   }

  //   return () =>
  //     clearSelectedProductTimeoutID &&
  //     clearTimeout(clearSelectedProductTimeoutID);
  // }, [isDrawerOpen, theme.transitions.duration.leavingScreen]);

  return (
    <>
      <AppBar position="fixed" elevation={0}>
        <Toolbar>
          <IconButton
            edge="start"
            size="large"
            color="inherit"
            sx={{ ml: -0.25 }}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIosNewIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ fontWeight: 'bold', flexGrow: 1, mx: 0.25 }}
          >
            {subcategory ? subcategory.name : <Skeleton animation="wave" />}
          </Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      {isImageLoading && (
        <Skeleton
          variant="rectangle"
          animation="wave"
          sx={{ height: '25.875rem' }}
        />
      )}
      {subcategory && (
        <Box
          component="img"
          sx={[
            {
              aspectRatio: '1',
              objectFit: 'cover',
              width: 1,
              display: 'block',
            },
            isImageLoading && {
              display: 'none',
            },
          ]}
          src={subcategory.imageUrl}
          alt=""
          onLoad={() => setIsImageLoading(false)}
        />
      )}
      <Stack
        spacing={1.625}
        sx={[
          { px: 2.5, mt: 2.125 },
          cartProducts.length > 0 ? { mb: 11 } : { mb: 1.625 },
        ]}
      >
        {products &&
          products.length > 0 &&
          products.map((product) => {
            const foundCartProduct = cartProducts.find(
              (cartProduct) => cartProduct.id === product.id
            );
            return (
              <ProductCard
                cartProduct={foundCartProduct}
                product={product}
                key={product.id}
                onAddClick={() => {
                  // setSelectedProduct(product);
                  // setIsDrawerOpen(true);
                  addCartProduct(product);
                }}
                onNoteClick={() => {
                  setSelectedProduct(product);
                  if (foundCartProduct.note) setNote(foundCartProduct.note);
                  setIsDialogOpen(true);
                }}
              />
            );
          })}
        {products &&
          products.length === 0 &&
          [...new Array(5)].map((_, index) => (
            <Skeleton
              key={index}
              animation="wave"
              variant="rectangle"
              sx={{ height: '7.5rem', borderRadius: 2.5 }}
            />
          ))}

        {!products && <Typography>No data</Typography>}
      </Stack>
      {cartProducts.length > 0 && (
        <Container
          sx={{
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            px: 2.5,
            bgcolor: 'background.default',
          }}
          maxWidth="false"
        >
          <Button
            variant="contained"
            sx={{
              width: 1,
              borderRadius: 2.5,
              height: '3.125rem',
              display: 'flex',
              justifyContent: 'space-between',
              px: 3,
              my: 1.5625,
            }}
            disableElevation
            component={RouterLink}
            to="/checkout"
          >
            <Typography>{getCartProductsTotalItems()} items</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              {formatNumberToCurrency(getCartProductsTotalPrice())}
            </Typography>
          </Button>
        </Container>
      )}
      {/* <SwipeableDrawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onOpen={() => {}}
        disableSwipeToOpen
        PaperProps={{
          sx: {
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
          },
        }}
      >
        <Divider
          sx={{
            mt: 1.5,
            mx: 19.625,
            bgcolor: 'primary.main',
            height: '0.3125rem',
            border: 0,
            borderRadius: 1.25,
          }}
        />
        <Box
          component="img"
          sx={{
            objectFit: 'cover',
            aspectRatio:'1',
            mt: 1.25,
            mx: 2.5,
            borderRadius: 5,
          }}
          src={selectedProduct && selectedProduct.imageUrl}
          alt=""
        />
        <Typography sx={{ mt: 2.125, mx: 2.5, fontWeight: 'bold' }}>
          {selectedProduct && selectedProduct.name}
        </Typography>
        <Typography sx={{ mx: 2.5, mt: 0.625 }} variant="body2">
          {selectedProduct && selectedProduct.description}
        </Typography>
        <Typography
          sx={{ fontWeight: 'bold', mx: 2.5, mt: 1 }}
          color="primary.dark"
        >
          {selectedProduct && formatNumberToCurrency(selectedProduct.price)}
        </Typography>
        <Button
          variant="contained"
          sx={{
            mb: 2.25,
            mt: 2.75,
            mx: 2.5,
            py: 1.25,
            borderRadius: 2.5,
          }}
          disableElevation
          onClick={() => {
            addCartProduct(selectedProduct);
            setIsDrawerOpen(false);
          }}
        >
          Tambah ke keranjang
        </Button>
      </SwipeableDrawer> */}
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        PaperProps={{ sx: { minWidth: '20.625rem', width: '80%' } }}
      >
        <DialogTitle
          sx={{
            typography: 'body2',
            fontWeight: 'bold',
            pt: 2.25,
            px: 2.5,
            pb: 0.625,
          }}
        >
          Catatan
        </DialogTitle>
        <DialogContent sx={{ pb: 0, px: 2.5 }}>
          <TextField
            fullWidth
            InputProps={{
              sx: {
                typography: 'caption',
                color: 'grey.700',
              },
            }}
            multiline
            variant="standard"
            value={note}
            onChange={(event) => setNote(event.target.value)}
          />
        </DialogContent>
        <DialogActions sx={{ pb: 1.875, px: 2.5, pt: 1.625 }}>
          <Button
            autoFocus
            variant="contained"
            fullWidth
            disableElevation
            onClick={() => {
              updateCartProductNote(selectedProduct.id, note);
              setIsDialogOpen(false);
            }}
            sx={{
              typography: 'body2',
              fontWeight: 600,
              py: 1.25,
              borderRadius: 2.5,
            }}
          >
            Simpan
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Menu;
