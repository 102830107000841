import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
  MenuCategory,
  Menu,
  Checkout,
  Histories,
  Login,
  NotFound,
  HistoryDetail,
  RequireAuth,
  RequireHasCartProducts,
} from './components';
import { CartContextProvider } from './contexts/CartContext';
import { AuthContextProvider } from './contexts/AuthContext';
import { SnackbarContextProvider } from './contexts/SnackbarContext';
import { Snackbar, Alert } from '@mui/material';
import MenuSubcategory from './components/MenuSubcategory';

const App = () => (
  <CartContextProvider>
    <AuthContextProvider>
      <SnackbarContextProvider>
        {({ isOpen: isSnackbarOpen, close: closeSnackbar }) => (
          <>
            <BrowserRouter>
              <Routes>
                <Route index element={<Login />} />
                <Route element={<RequireAuth />}>
                  <Route path="categories">
                    <Route index element={<MenuCategory />} />
                    <Route path=":categoryId">
                      <Route index element={<MenuSubcategory />} />
                      <Route path=":subcategoryId" element={<Menu />} />
                    </Route>
                  </Route>
                  <Route
                    path="checkout"
                    element={
                      <RequireHasCartProducts>
                        <Checkout />
                      </RequireHasCartProducts>
                    }
                  />
                  <Route path="histories">
                    <Route index element={<Histories />} />
                    <Route path=":historyId" element={<HistoryDetail />} />
                  </Route>
                </Route>
                <Route path="*" element={<NotFound />} />
              </Routes>
            </BrowserRouter>
            <Snackbar
              open={isSnackbarOpen}
              autoHideDuration={3000}
              onClose={closeSnackbar}
            >
              <Alert severity="success">Pesanan berhasil dibuat!</Alert>
            </Snackbar>
          </>
        )}
      </SnackbarContextProvider>
    </AuthContextProvider>
  </CartContextProvider>
);

export default App;
