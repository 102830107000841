import { createContext, useState, useEffect, useCallback } from 'react';

export const CartContext = createContext({
  cartProducts: [],
  addCartProduct: () => {},
  updateCartProductQty: () => {},
  removeCartProduct: () => {},
  getCartProductsTotalItems: () => {},
  getCartProductsTotalPrice: () => {},
  updateCartProductNote: () => {},
  clearCart: () => {},
});

export const CartContextProvider = ({ children }) => {
  const [cartProducts, setCartProducts] = useState([]);
  const [isCartProductsInitialized, setIsCartProductsInitialized] =
    useState(false);

  useEffect(() => {
    if (cartProducts.length > 0) {
      localStorage.setItem('cartProducts', JSON.stringify(cartProducts));
    } else if (isCartProductsInitialized) {
      localStorage.removeItem('cartProducts');
    }
  }, [cartProducts, isCartProductsInitialized]);

  useEffect(() => {
    const storageCartProducts = localStorage.getItem('cartProducts');

    if (!storageCartProducts) {
      setIsCartProductsInitialized(true);
      return;
    }

    try {
      const parsedStorageCartProducts = JSON.parse(storageCartProducts);
      setCartProducts(parsedStorageCartProducts);
    } catch {
      localStorage.removeItem('cartProducts');
    } finally {
      setIsCartProductsInitialized(true);
    }
  }, []);

  const addCartProduct = (product) =>
    setCartProducts((prevState) => [
      ...prevState,
      { ...product, qty: 1, note: '' },
    ]);

  const updateCartProductQty = (id, qty) =>
    setCartProducts((prevState) => {
      const foundIndex = prevState.findIndex((product) => product.id === id);
      const currentState = [...prevState];
      currentState[foundIndex].qty = qty;
      return currentState;
    });

  const removeCartProduct = (id) =>
    setCartProducts((prevState) =>
      prevState.filter((product) => product.id !== id)
    );

  const getCartProductsTotalPrice = () =>
    cartProducts.reduce(
      (previousPrice, currentProduct) =>
        previousPrice + currentProduct.price * currentProduct.qty,
      0
    );

  const getCartProductsTotalItems = () =>
    cartProducts.reduce(
      (previousQty, currentProduct) => previousQty + currentProduct.qty,
      0
    );

  const updateCartProductNote = (id, note) =>
    setCartProducts((prevState) => {
      const foundIndex = prevState.findIndex((product) => product.id === id);
      const currentState = [...prevState];
      currentState[foundIndex].note = note;
      return currentState;
    });

  const clearCart = useCallback(() => setCartProducts([]), []);

  return (
    <CartContext.Provider
      value={{
        cartProducts,
        addCartProduct,
        updateCartProductQty,
        removeCartProduct,
        getCartProductsTotalItems,
        getCartProductsTotalPrice,
        updateCartProductNote,
        clearCart,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
