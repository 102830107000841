import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { CartContext } from '../contexts/CartContext';

const RequireHasCartProducts = ({ children }) => {
  const { cartProducts } = useContext(CartContext);

  if (cartProducts.length > 0) return children;

  return <Navigate to="/categories" replace />;
};

export default RequireHasCartProducts;
